import React from 'react';
import './home.css';
import { Helmet } from 'react-helmet';
import VerseOfTheDay from './VerseOfTheDay';

function Home() {

      return (
            <div className="mc">
                  <>
                        <Helmet>
                              <title>Tamil Bible | தமிழ் வேதாகமம் | Holy Bible Tamil | Digital Bible</title>
                              <meta
                                    name="description"
                                    content="TamilBible | தமிழ் மொழியில் பரிசுத்த வேதாகமம் | Tamil Bible Verse | Digital Bible in Tamil Language | பழைய ஏற்பாடு | புதிய ஏற்பாடு"
                              />
                        </Helmet>
                        <div className='home_container'>
                              <div className='home'>
                                    <div className='flex'>
                                          <VerseOfTheDay />
                                    </div>
                                    <br />
                                    <br />
                                    <h1 align="center">Tamil Bible - தமிழ் வேதாகமம்</h1>
                                    <br />
                                    <br />
                                    <div className='home_introduction'>
                                          <div lang="en" className='lang'>
                                                <h3 align="center" className='home_introduction_heading'>
                                                      The Holy Bible Preface
                                                </h3>
                                                <div className='content'>

                                                      <br />
                                                      <h2>Exploring the Power of Tamil Bible Verse</h2>

                                                      <br />
                                                      <p>The Tamil Bible, a translation of the Holy Scriptures into one of the world's oldest languages, is a treasure trove of wisdom, guidance, and spiritual nourishment. This article aims to explore the power of Tamil Bible verses, or tamilbibleverse, and how they can impact our lives.</p>

                                                      <br /><h2>The Tamil Bible A Brief Overview</h2>
                                                      <br />
                                                      <h2>Tamil Bible Verse | தமிழ் வேதாகமம் | Holy Bible in Tamil English</h2>
                                                      <br />
                                                      <p>The Tamil Bible is a translation of the Christian Bible into Tamil, a language spoken by over 70 million people worldwide. The first Tamil Bible was published in 1715 by Bartholom&auml;us Ziegenbalg, a German missionary. Since then, the Tamil Bible has been a source of spiritual guidance and inspiration for Tamil-speaking Christians around the world.</p>

                                                      <br /><h2>The Power of a Bible Verse</h2>
                                                      <br />
                                                      <p>A Bible verse, whether in English, Tamil, or any other language, is more than just a sentence or two from the Bible. It is a nugget of divine wisdom, a message from God that can guide us, comfort us, and inspire us. A tamilbibleverse can be a powerful tool for personal growth and spiritual development.</p>

                                                      <br /><h2>The Impact of Tamil Bible Verses</h2>
                                                      <br />
                                                      <p>The power of Tamil Bible verses lies in their ability to speak directly to the heart of the reader. The Tamil language, with its rich literary tradition and expressive vocabulary, allows for a deep and nuanced understanding of the Bible's teachings. Here are a few ways in which Tamil Bible verses can impact our lives:</p>

                                                      <br /><h3>Spiritual Growth</h3>
                                                      <br />
                                                      <p>Tamil Bible verses can help us grow spiritually. They can deepen our understanding of God's nature and His plan for us. They can also challenge us to live according to God's principles and to strive for holiness in our daily lives.</p>

                                                      <br /><h3>Comfort and Encouragement</h3>
                                                      <br />
                                                      <p>In times of trouble, a tamilbibleverse can provide comfort and encouragement. It can remind us of God's love and faithfulness, and it can give us hope for the future. It can also strengthen our faith and help us to trust in God's promises.</p>

                                                      <br /><h3>Guidance and Wisdom</h3>
                                                      <br />
                                                      <p>Tamil Bible verses can offer guidance and wisdom for life's challenges. They can provide practical advice for dealing with difficult situations, and they can give us insight into God's will for our lives. They can also teach us important values such as love, forgiveness, and humility.</p>

                                                      <br />
                                                      <h3>Conclusion</h3>
                                                      <br />
                                                      <p>The Tamil Bible is a rich resource for Tamil-speaking Christians and anyone interested in exploring the Bible in a new language. The power of a tamilbibleverse lies in its ability to touch the heart, inspire the mind, and nourish the soul. Whether you're seeking spiritual growth, comfort and encouragement, or guidance and wisdom, the Tamil Bible has something to offer you. So why not explore the power of Tamil Bible verses further today? We hope this website has the answer...</p>
                                                      <p></p>

                                                </div>
                                          </div>
                                          <div className='lang tamil-lang'>
                                                <h3 align="center" className='home_introduction_heading'>
                                                      தமிழ் வேதாகமம் அறிமுகம்
                                                </h3>
                                                <div className='content'>

                                                      <br /><h2>தமிழ் வேதாகம வசனங்களின் சக்தியை ஆராய்தல்</h2>
                                                      <br />
                                                      <p>உலகின் தொன்மையான மொழிகளில் ஒன்றான பரிசுத்த வேதாகமத்தின் மொழிபெயர்ப்பான தமிழ் வேதாகமம் ஞானம், வழிகாட்டுதல் மற்றும் ஆன்மீக ஊட்டச்சத்தின் பொக்கிஷமாகும். இக்கட்டுரை தமிழ் வேதாகம வசனங்களின் ஆற்றலையும், அவை நம் வாழ்வை எவ்வாறு மாற்றலாம் என்பதையும் ஆராய்வதை நோக்கமாகக் கொண்டுள்ளது.</p>

                                                      <br /><h2>தமிழ் வேதாகமம் ஒரு சுருக்கமான கண்ணோட்டம்</h2>
                                                      <br />
                                                      <p>தமிழ் வேதாகமம் என்பது கிறிஸ்தவ வேதாகமத்தின் - Tamil Bible தமிழாக்கமாகும், இது உலகளவில் 70 மில்லியனுக்கும் அதிகமான மக்களால் பேசப்படும் மொழியாகும். முதல் தமிழ் விவிலியம் 1715 ஆம் ஆண்டில் ஜெர்மானிய மிஷனரியான பர்தோலோமஸ் சீகன்பால்க் என்பவரால் வெளியிடப்பட்டது. அப்போதிருந்து, தமிழ் வேதாகமம் உலகெங்கிலும் உள்ள தமிழ் பேசும் கிறிஸ்தவர்களுக்கு ஆன்மீக வழிகாட்டுதலாகவும் உத்வேகமாகவும் இருந்து வருகிறது.</p>

                                                      <br /><h2>ஒரு வேதாகமம் வசனத்தின் சக்தி</h2>
                                                      <br />
                                                      <p>ஒரு வேதாகமம் வசனம், ஆங்கிலம், தமிழ் அல்லது வேறு எந்த மொழியாக இருந்தாலும், அது வேதாகமத்திலிருந்து ஒன்று அல்லது இரண்டு வாக்கியங்களை விட அதிகமாகும். இது தெய்வீக ஞானத்தின் ஒரு துண்டு, கடவுளிடமிருந்து வரும் ஒரு செய்தி, இது நம்மை வழிநடத்தவும், ஆறுதல்படுத்தவும், நம்மை ஊக்குவிக்கவும் முடியும். தனிமனித வளர்ச்சிக்கும் ஆன்மீக வளர்ச்சிக்கும் தமிழின் ஒரு சக்திவாய்ந்த கருவியாக இருக்க முடியும்.</p>

                                                      <br /><h2>தமிழ் வேதாகம வசனங்களின் தாக்கம்</h2>
                                                      <br />
                                                      <p>தமிழ் வேதாகம வசனங்களின் சக்தி வாசிப்பவர்களின் இதயத்துடன் நேரடியாகப் பேசும் திறனில் உள்ளது. தமிழ் மொழி, அதன் வளமான இலக்கிய பாரம்பரியம் மற்றும் வெளிப்படையான சொற்களஞ்சியம் ஆகியவற்றைக் கொண்டு, தமிழ் வேதாகமத்தின் போதனைகளை ஆழமாகவும் நுணுக்கமாகவும் புரிந்துகொள்ள அனுமதிக்கிறது. தமிழ் வேதாகம வசனங்கள் நம் வாழ்க்கையை மாற்றக்கூடிய சில வழிகள் இங்கே:</p>

                                                      <br /><h3>ஆன்மீக வளர்ச்சி</h3>
                                                      <br />
                                                      <p>தமிழ் வேதாகம வசனங்கள் நாம் ஆன்மீக ரீதியாக வளர உதவும். அவை கடவுளுடைய இயல்பையும் நமக்கான அவரது திட்டத்தையும் பற்றிய நமது புரிதலை ஆழப்படுத்தக்கூடும். கடவுளுடைய நியமங்களின்படி வாழவும், நமது அன்றாட வாழ்க்கையில் பரிசுத்தத்திற்காக பாடுபடவும் தமிழ் வேதாகமம் நமக்கு சவால் விடலாம்.</p>

                                                      <br /><h3>ஆறுதல் மற்றும் ஊக்கம்</h3>
                                                      <br />
                                                      <p>இக்கட்டான நேரங்களில் தமிழ் வசனம் ஆறுதலையும் ஊக்கத்தையும் தர முடியும். இது கடவுளுடைய அன்பையும் உண்மைத்தன்மையையும் நமக்கு நினைவூட்டலாம், மேலும் அது எதிர்காலத்தைப் பற்றிய நம்பிக்கையையும் கொடுக்கலாம். அது நம்முடைய விசுவாசத்தை பலப்படுத்தலாம், கடவுளுடைய வாக்குத்தத்தங்களில் நம்பிக்கை வைக்கவும் உதவும்.</p>

                                                      <br /><h3>வழிகாட்டல் மற்றும் ஞானம்</h3>
                                                      <br />
                                                      <p>தமிழ் வேதாகம வசனங்கள் வாழ்க்கையின் சவால்களுக்கு வழிகாட்டுதலையும் ஞானத்தையும் வழங்க முடியும். கடினமான சூழ்நிலைகளைக் கையாள்வதற்கான நடைமுறை ஆலோசனைகளை தமிழ் வேதாகமம் வழங்க முடியும், மேலும் நம் வாழ்க்கைக்கான கடவுளுடைய சித்தத்தைப் பற்றிய நுண்ணறிவை தமிழ் வேதாகமம் நமக்கு வழங்க முடியும். அன்பு, மன்னிப்பு மற்றும் பணிவு போன்ற முக்கியமான மதிப்புகளையும் தமிழ் வேதாகமம் நமக்கு கற்பிக்க முடியும்.</p>

                                                      <br /><h3>முடிவுரை</h3>
                                                      <br />
                                                      <p>தமிழ் பேசும் கிறிஸ்தவர்களுக்கும், வேதாகமத்தை ஒரு புதிய மொழியில் ஆராய்வதில் ஆர்வமுள்ள எவருக்கும் தமிழ் வேதாகம் ஒரு வளமான ஆதாரமாகும். இதயத்தைத் தொட்டு, மனதைத் தூண்டி, ஆன்மாவை வளர்க்கும் ஆற்றலில்தான் தமிழ் வசனத்தின் சக்தி அடங்கியிருக்கிறது. நீங்கள் ஆன்மீக வளர்ச்சி, ஆறுதல் மற்றும் ஊக்கம் அல்லது வழிகாட்டுதல் மற்றும் ஞானத்தை தேடுகிறீர்கள் என்றால், தமிழ் வேதாகமம் உங்களுக்கு நீங்கள் தேடுவதை வழங்குகிறது. இன்று தமிழ் வேதாகம வசனங்களின் சக்தியை ஏன் தொடர்ந்து படித்து ஆராயக் கூடாது? இந்த வலைத்தளத்தில் அதற்கான விடை இருப்பதாக நம்புகிறோம்... </p>
                                                      <p></p>
                                                </div>
                                          </div>
                                    </div>

                              </div>

                        </div>
                  </>
            </div>
      );
}

export default Home;
